import { BodyText } from "@lmig/lmds-react";
import { AddressUpdateDisplay } from "./components/AddressUpdateDisplay";
import { LoadingSpinner } from "@lmig/lmds-react";
import { ErrorComponent } from "../../components/error/ErrorComponent";
import { STRINGS } from "../../utilities/strings";
import { AccountStatus } from "@lmig/paymenthub-status-library";
import { useUpdateAccountStatus } from "../../components/confirmation/useUpdateAccountStatus";

export const RequestAddressUpdateConfirmation = () => {
  const { updateAccountCallFinished, updateAccountError } =
    useUpdateAccountStatus(AccountStatus.CHECK_SELECTED_ADDRESS_INVALID);

  if (!updateAccountCallFinished) {
    return <LoadingSpinner />;
  }

  const {
    ADDRESS_UPDATE_FAILED_ALERT,
    ADDRESS_UPDATE_FAILED_MESSAGE,
    GENERIC_PAYMENT_ERROR,
    CONTACT_REP,
  } = STRINGS;

  return (
    <>
      {updateAccountError ? (
        <ErrorComponent
          title={GENERIC_PAYMENT_ERROR}
          alert={ADDRESS_UPDATE_FAILED_ALERT}
          message={`${ADDRESS_UPDATE_FAILED_MESSAGE} \n ${CONTACT_REP} \n `}
        />
      ) : (
        <>
          <AddressUpdateDisplay />
          <BodyText type="lead">
            {
              "Thank you. Your Liberty representative will be in touch to update your address."
            }
          </BodyText>
        </>
      )}
    </>
  );
};
