import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import { ConfirmationSuccess } from "../../components/confirmation/ConfirmationSuccess";
import { ErrorComponent } from "../../components/error/ErrorComponent";
import { STRINGS } from "../../utilities/strings";
import { AccountStatus } from "@lmig/paymenthub-status-library";
import { useUpdateAccountStatus } from "../../components/confirmation/useUpdateAccountStatus";

export const CheckConfirmation = () => {
  const { updateAccountCallFinished, updateAccountError } =
    useUpdateAccountStatus(AccountStatus.CHECK_SELECTED_ADDRESS_CONFIRMED);

  if (!updateAccountCallFinished) {
    return <LoadingSpinner />;
  }

  const {
    CHECK_CONFIRMATION_HEADING,
    CHECK_CONFIRMATION_BODY,
    CHECK_REQUEST_FAILED_ALERT,
    CHECK_REQUEST_FAILED_MESSAGE,
    GENERIC_PAYMENT_ERROR,
    CONTACT_REP,
  } = STRINGS;

  return (
    <>
      {updateAccountError ? (
        <ErrorComponent
          title={GENERIC_PAYMENT_ERROR}
          alert={CHECK_REQUEST_FAILED_ALERT}
          message={`${CHECK_REQUEST_FAILED_MESSAGE} \n ${CONTACT_REP} \n `}
        />
      ) : (
        <ConfirmationSuccess
          heading={CHECK_CONFIRMATION_HEADING}
          bodyText={CHECK_CONFIRMATION_BODY}
        />
      )}
    </>
  );
};
