import { useEffect, useState } from "react";
import { apiGwService } from "../../services/api-gw-service";
import { AccountStatus } from "@lmig/paymenthub-status-library";
import { useAppState } from "../../context/AppContext";

export const useUpdateAccountStatus = (status: AccountStatus) => {
  const { state } = useAppState();
  const { user } = state;
  const [updateAccountCallFinished, setUpdateAccountCallFinished] =
    useState(false);
  const [updateAccountError, setUpdateAccountError] = useState(false);

  useEffect(() => {
    if (!updateAccountCallFinished) {
      apiGwService.updateAccountStatus
        .updateAccountStatus(status, user?.id || "")
        .then((response) => {
          if (!response.accountUpdatedSuccessfully || response.errorOccured) {
            setUpdateAccountError(true);
          }
          setUpdateAccountCallFinished(true);
        });
    }
  }, [updateAccountCallFinished, status, user?.id]);

  return { updateAccountCallFinished, updateAccountError };
};
